import _isWhitespaceCharacter from "is-whitespace-character";
import _delete from "../locate/delete";
var exports = {};
var whitespace = _isWhitespaceCharacter;
var locate = _delete;
exports = strikethrough;
strikethrough.locator = locate;
var tilde = "~";
var fence = "~~";

function strikethrough(eat, value, silent) {
  var self = this;
  var character = "";
  var previous = "";
  var preceding = "";
  var subvalue = "";
  var index;
  var length;
  var now;

  if (!self.options.gfm || value.charAt(0) !== tilde || value.charAt(1) !== tilde || whitespace(value.charAt(2))) {
    return;
  }

  index = 1;
  length = value.length;
  now = eat.now();
  now.column += 2;
  now.offset += 2;

  while (++index < length) {
    character = value.charAt(index);

    if (character === tilde && previous === tilde && (!preceding || !whitespace(preceding))) {
      /* istanbul ignore if - never used (yet) */
      if (silent) {
        return true;
      }

      return eat(fence + subvalue + fence)({
        type: "delete",
        children: self.tokenizeInline(subvalue, now)
      });
    }

    subvalue += previous;
    preceding = previous;
    previous = character;
  }
}

export default exports;