import _break from "../locate/break";
var exports = {};
var locate = _break;
exports = hardBreak;
hardBreak.locator = locate;
var space = " ";
var lineFeed = "\n";
var minBreakLength = 2;

function hardBreak(eat, value, silent) {
  var length = value.length;
  var index = -1;
  var queue = "";
  var character;

  while (++index < length) {
    character = value.charAt(index);

    if (character === lineFeed) {
      if (index < minBreakLength) {
        return;
      }
      /* istanbul ignore if - never used (yet) */


      if (silent) {
        return true;
      }

      queue += character;
      return eat(queue)({
        type: "break"
      });
    }

    if (character !== space) {
      return;
    }

    queue += character;
  }
}

export default exports;