import _html from "../util/html";
var exports = {};
var openCloseTag = _html.openCloseTag;
exports = blockHtml;
var tab = "\t";
var space = " ";
var lineFeed = "\n";
var lessThan = "<";
var rawOpenExpression = /^<(script|pre|style)(?=(\s|>|$))/i;
var rawCloseExpression = /<\/(script|pre|style)>/i;
var commentOpenExpression = /^<!--/;
var commentCloseExpression = /-->/;
var instructionOpenExpression = /^<\?/;
var instructionCloseExpression = /\?>/;
var directiveOpenExpression = /^<![A-Za-z]/;
var directiveCloseExpression = />/;
var cdataOpenExpression = /^<!\[CDATA\[/;
var cdataCloseExpression = /]]>/;
var elementCloseExpression = /^$/;
var otherElementOpenExpression = new RegExp(openCloseTag.source + "\\s*$");

function blockHtml(eat, value, silent) {
  var self = this;
  var blocks = self.options.blocks.join("|");
  var elementOpenExpression = new RegExp("^</?(" + blocks + ")(?=(\\s|/?>|$))", "i");
  var length = value.length;
  var index = 0;
  var next;
  var line;
  var offset;
  var character;
  var count;
  var sequence;
  var subvalue;
  var sequences = [[rawOpenExpression, rawCloseExpression, true], [commentOpenExpression, commentCloseExpression, true], [instructionOpenExpression, instructionCloseExpression, true], [directiveOpenExpression, directiveCloseExpression, true], [cdataOpenExpression, cdataCloseExpression, true], [elementOpenExpression, elementCloseExpression, true], [otherElementOpenExpression, elementCloseExpression, false]]; // Eat initial spacing.

  while (index < length) {
    character = value.charAt(index);

    if (character !== tab && character !== space) {
      break;
    }

    index++;
  }

  if (value.charAt(index) !== lessThan) {
    return;
  }

  next = value.indexOf(lineFeed, index + 1);
  next = next === -1 ? length : next;
  line = value.slice(index, next);
  offset = -1;
  count = sequences.length;

  while (++offset < count) {
    if (sequences[offset][0].test(line)) {
      sequence = sequences[offset];
      break;
    }
  }

  if (!sequence) {
    return;
  }

  if (silent) {
    return sequence[2];
  }

  index = next;

  if (!sequence[1].test(line)) {
    while (index < length) {
      next = value.indexOf(lineFeed, index + 1);
      next = next === -1 ? length : next;
      line = value.slice(index + 1, next);

      if (sequence[1].test(line)) {
        if (line) {
          index = next;
        }

        break;
      }

      index = next;
    }
  }

  subvalue = value.slice(0, index);
  return eat(subvalue)({
    type: "html",
    value: subvalue
  });
}

export default exports;