import _collapseWhiteSpace from "collapse-white-space";
var exports = {};
var collapseWhiteSpace = _collapseWhiteSpace;
exports = normalize; // Normalize an identifier.  Collapses multiple white space characters into a
// single space, and removes casing.

function normalize(value) {
  return collapseWhiteSpace(value).toLowerCase();
}

export default exports;