import _isDecimal from "is-decimal";
import _isAlphabetical from "is-alphabetical";
var exports = {};
var decimal = _isDecimal;
var alphabetical = _isAlphabetical;
var plusSign = 43; // '+'

var dash = 45; // '-'

var dot = 46; // '.'

var underscore = 95; // '_'

exports = locate; // See: <https://github.github.com/gfm/#extended-email-autolink>

function locate(value, fromIndex) {
  var self = this;
  var at;
  var position;

  if (!this.options.gfm) {
    return -1;
  }

  at = value.indexOf("@", fromIndex);

  if (at === -1) {
    return -1;
  }

  position = at;

  if (position === fromIndex || !isGfmAtext(value.charCodeAt(position - 1))) {
    return locate.call(self, value, at + 1);
  }

  while (position > fromIndex && isGfmAtext(value.charCodeAt(position - 1))) {
    position--;
  }

  return position;
}

function isGfmAtext(code) {
  return decimal(code) || alphabetical(code) || code === plusSign || code === dash || code === dot || code === underscore;
}

export default exports;