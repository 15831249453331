import _isAlphabetical from "is-alphabetical";
import _tag from "../locate/tag";
import _html from "../util/html";
var exports = {};
var alphabetical = _isAlphabetical;
var locate = _tag;
var tag = _html.tag;
exports = inlineHTML;
inlineHTML.locator = locate;
var lessThan = "<";
var questionMark = "?";
var exclamationMark = "!";
var slash = "/";
var htmlLinkOpenExpression = /^<a /i;
var htmlLinkCloseExpression = /^<\/a>/i;

function inlineHTML(eat, value, silent) {
  var self = this;
  var length = value.length;
  var character;
  var subvalue;

  if (value.charAt(0) !== lessThan || length < 3) {
    return;
  }

  character = value.charAt(1);

  if (!alphabetical(character) && character !== questionMark && character !== exclamationMark && character !== slash) {
    return;
  }

  subvalue = value.match(tag);

  if (!subvalue) {
    return;
  }
  /* istanbul ignore if - not used yet. */


  if (silent) {
    return true;
  }

  subvalue = subvalue[0];

  if (!self.inLink && htmlLinkOpenExpression.test(subvalue)) {
    self.inLink = true;
  } else if (self.inLink && htmlLinkCloseExpression.test(subvalue)) {
    self.inLink = false;
  }

  return eat(subvalue)({
    type: "html",
    value: subvalue
  });
}

export default exports;