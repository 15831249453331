import _unherit from "unherit";
import _xtend from "xtend";
import _parser from "./lib/parser.js";
var exports = {};
var unherit = _unherit;
var xtend = _xtend;
var Parser = _parser;
exports = parse;
parse.Parser = Parser;

function parse(options) {
  var settings = this.data("settings");
  var Local = unherit(Parser);
  Local.prototype.options = xtend(Local.prototype.options, settings, options);
  this.Parser = Local;
}

export default exports;