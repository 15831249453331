var exports = {};
exports = setextHeading;
var lineFeed = "\n";
var tab = "\t";
var space = " ";
var equalsTo = "=";
var dash = "-";
var maxIndent = 3;
var equalsToDepth = 1;
var dashDepth = 2;

function setextHeading(eat, value, silent) {
  var self = this;
  var now = eat.now();
  var length = value.length;
  var index = -1;
  var subvalue = "";
  var content;
  var queue;
  var character;
  var marker;
  var depth; // Eat initial indentation.

  while (++index < length) {
    character = value.charAt(index);

    if (character !== space || index >= maxIndent) {
      index--;
      break;
    }

    subvalue += character;
  } // Eat content.


  content = "";
  queue = "";

  while (++index < length) {
    character = value.charAt(index);

    if (character === lineFeed) {
      index--;
      break;
    }

    if (character === space || character === tab) {
      queue += character;
    } else {
      content += queue + character;
      queue = "";
    }
  }

  now.column += subvalue.length;
  now.offset += subvalue.length;
  subvalue += content + queue; // Ensure the content is followed by a newline and a valid marker.

  character = value.charAt(++index);
  marker = value.charAt(++index);

  if (character !== lineFeed || marker !== equalsTo && marker !== dash) {
    return;
  }

  subvalue += character; // Eat Setext-line.

  queue = marker;
  depth = marker === equalsTo ? equalsToDepth : dashDepth;

  while (++index < length) {
    character = value.charAt(index);

    if (character !== marker) {
      if (character !== lineFeed) {
        return;
      }

      index--;
      break;
    }

    queue += character;
  }

  if (silent) {
    return true;
  }

  return eat(subvalue + queue)({
    type: "heading",
    depth: depth,
    children: self.tokenizeInline(content, now)
  });
}

export default exports;