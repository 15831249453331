import _xtend from "xtend";
import _unistUtilRemovePosition from "unist-util-remove-position";
var exports = {};
var xtend = _xtend;
var removePosition = _unistUtilRemovePosition;
exports = parse;
var lineFeed = "\n";
var lineBreaksExpression = /\r\n|\r/g; // Parse the bound file.

function parse() {
  var self = this;
  var value = String(self.file);
  var start = {
    line: 1,
    column: 1,
    offset: 0
  };
  var content = xtend(start);
  var node; // Clean non-unix newlines: `\r\n` and `\r` are all changed to `\n`.
  // This should not affect positional information.

  value = value.replace(lineBreaksExpression, lineFeed); // BOM.

  if (value.charCodeAt(0) === 65279) {
    value = value.slice(1);
    content.column++;
    content.offset++;
  }

  node = {
    type: "root",
    children: self.tokenizeBlock(value, content),
    position: {
      start: start,
      end: self.eof || xtend(start)
    }
  };

  if (!self.options.position) {
    removePosition(node, true);
  }

  return node;
}

export default exports;