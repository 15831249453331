import _xtend from "xtend";
import _parseEntities from "parse-entities";
var exports = {};
var xtend = _xtend;
var entities = _parseEntities;
exports = factory; // Factory to create an entity decoder.

function factory(ctx) {
  decoder.raw = decodeRaw;
  return decoder; // Normalize `position` to add an `indent`.

  function normalize(position) {
    var offsets = ctx.offset;
    var line = position.line;
    var result = [];

    while (++line) {
      if (!(line in offsets)) {
        break;
      }

      result.push((offsets[line] || 0) + 1);
    }

    return {
      start: position,
      indent: result
    };
  } // Decode `value` (at `position`) into text-nodes.


  function decoder(value, position, handler) {
    entities(value, {
      position: normalize(position),
      warning: handleWarning,
      text: handler,
      reference: handler,
      textContext: ctx,
      referenceContext: ctx
    });
  } // Decode `value` (at `position`) into a string.


  function decodeRaw(value, position, options) {
    return entities(value, xtend(options, {
      position: normalize(position),
      warning: handleWarning
    }));
  } // Handle a warning.
  // See <https://github.com/wooorm/parse-entities> for the warnings.


  function handleWarning(reason, position, code) {
    if (code !== 3) {
      ctx.file.message(reason, position);
    }
  }
}

export default exports;