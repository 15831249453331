var exports = {};
exports = factory;
var backslash = "\\"; // Factory to de-escape a value, based on a list at `key` in `ctx`.

function factory(ctx, key) {
  return unescape; // De-escape a string using the expression at `key` in `ctx`.

  function unescape(value) {
    var previous = 0;
    var index = value.indexOf(backslash);
    var escape = ctx[key];
    var queue = [];
    var character;

    while (index !== -1) {
      queue.push(value.slice(previous, index));
      previous = index + 1;
      character = value.charAt(previous); // If the following character is not a valid escape, add the slash.

      if (!character || escape.indexOf(character) === -1) {
        queue.push(backslash);
      }

      index = value.indexOf(backslash, previous + 1);
    }

    queue.push(value.slice(previous));
    return queue.join("");
  }
}

export default exports;