import _trim from "trim";
import _trimTrailingLines from "trim-trailing-lines";
import _interrupt from "../util/interrupt";
var exports = {};
var trim = _trim;
var trimTrailingLines = _trimTrailingLines;
var interrupt = _interrupt;
exports = paragraph;
var tab = "\t";
var lineFeed = "\n";
var space = " ";
var tabSize = 4; // Tokenise paragraph.

function paragraph(eat, value, silent) {
  var self = this;
  var settings = self.options;
  var commonmark = settings.commonmark;
  var tokenizers = self.blockTokenizers;
  var interruptors = self.interruptParagraph;
  var index = value.indexOf(lineFeed);
  var length = value.length;
  var position;
  var subvalue;
  var character;
  var size;
  var now;

  while (index < length) {
    // Eat everything if there’s no following newline.
    if (index === -1) {
      index = length;
      break;
    } // Stop if the next character is NEWLINE.


    if (value.charAt(index + 1) === lineFeed) {
      break;
    } // In commonmark-mode, following indented lines are part of the paragraph.


    if (commonmark) {
      size = 0;
      position = index + 1;

      while (position < length) {
        character = value.charAt(position);

        if (character === tab) {
          size = tabSize;
          break;
        } else if (character === space) {
          size++;
        } else {
          break;
        }

        position++;
      }

      if (size >= tabSize && character !== lineFeed) {
        index = value.indexOf(lineFeed, index + 1);
        continue;
      }
    }

    subvalue = value.slice(index + 1); // Check if the following code contains a possible block.

    if (interrupt(interruptors, tokenizers, self, [eat, subvalue, true])) {
      break;
    }

    position = index;
    index = value.indexOf(lineFeed, index + 1);

    if (index !== -1 && trim(value.slice(position, index)) === "") {
      index = position;
      break;
    }
  }

  subvalue = value.slice(0, index);
  /* istanbul ignore if - never used (yet) */

  if (silent) {
    return true;
  }

  now = eat.now();
  subvalue = trimTrailingLines(subvalue);
  return eat(subvalue)({
    type: "paragraph",
    children: self.tokenizeInline(subvalue, now)
  });
}

export default exports;