var exports = {};
exports = locate;
var values = ["www.", "http://", "https://"];

function locate(value, fromIndex) {
  var min = -1;
  var index;
  var length;
  var position;

  if (!this.options.gfm) {
    return min;
  }

  length = values.length;
  index = -1;

  while (++index < length) {
    position = value.indexOf(values[index], fromIndex);

    if (position !== -1 && (min === -1 || position < min)) {
      min = position;
    }
  }

  return min;
}

export default exports;