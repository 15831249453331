var exports = {};
exports = locate;

function locate(value, fromIndex) {
  var index = value.indexOf("\n", fromIndex);

  while (index > fromIndex) {
    if (value.charAt(index - 1) !== " ") {
      break;
    }

    index--;
  }

  return index;
}

export default exports;