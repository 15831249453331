import _xtend from "xtend";
import _stateToggle from "state-toggle";
import _vfileLocation from "vfile-location";
import _unescape from "./unescape";
import _decode from "./decode";
import _tokenizer from "./tokenizer";
import _setOptions from "./set-options";
import _parse from "./parse";
import _defaults from "./defaults";
import _blankLine from "./tokenize/blank-line";
import _codeIndented from "./tokenize/code-indented";
import _codeFenced from "./tokenize/code-fenced";
import _blockquote from "./tokenize/blockquote";
import _headingAtx from "./tokenize/heading-atx";
import _thematicBreak from "./tokenize/thematic-break";
import _list from "./tokenize/list";
import _headingSetext from "./tokenize/heading-setext";
import _htmlBlock from "./tokenize/html-block";
import _definition from "./tokenize/definition";
import _table from "./tokenize/table";
import _paragraph from "./tokenize/paragraph";
import _escape from "./tokenize/escape";
import _autoLink from "./tokenize/auto-link";
import _url from "./tokenize/url";
import _email from "./tokenize/email";
import _htmlInline from "./tokenize/html-inline";
import _link from "./tokenize/link";
import _reference from "./tokenize/reference";
import _strong from "./tokenize/strong";
import _emphasis from "./tokenize/emphasis";
import _delete from "./tokenize/delete";
import _codeInline from "./tokenize/code-inline";
import _break from "./tokenize/break";
import _text from "./tokenize/text";
var exports = {};
var xtend = _xtend;
var toggle = _stateToggle;
var vfileLocation = _vfileLocation;
var unescape = _unescape;
var decode = _decode;
var tokenizer = _tokenizer;
exports = Parser;

function Parser(doc, file) {
  this.file = file;
  this.offset = {};
  this.options = xtend(this.options);
  this.setOptions({});
  this.inList = false;
  this.inBlock = false;
  this.inLink = false;
  this.atStart = true;
  this.toOffset = vfileLocation(file).toOffset;
  this.unescape = unescape(this, "escape");
  this.decode = decode(this);
}

var proto = Parser.prototype; // Expose core.

proto.setOptions = _setOptions;
proto.parse = _parse; // Expose `defaults`.

proto.options = _defaults; // Enter and exit helpers.

proto.exitStart = toggle("atStart", true);
proto.enterList = toggle("inList", false);
proto.enterLink = toggle("inLink", false);
proto.enterBlock = toggle("inBlock", false); // Nodes that can interupt a paragraph:
//
// ```markdown
// A paragraph, followed by a thematic break.
// ___
// ```
//
// In the above example, the thematic break “interupts” the paragraph.

proto.interruptParagraph = [["thematicBreak"], ["list"], ["atxHeading"], ["fencedCode"], ["blockquote"], ["html"], ["setextHeading", {
  commonmark: false
}], ["definition", {
  commonmark: false
}]]; // Nodes that can interupt a list:
//
// ```markdown
// - One
// ___
// ```
//
// In the above example, the thematic break “interupts” the list.

proto.interruptList = [["atxHeading", {
  pedantic: false
}], ["fencedCode", {
  pedantic: false
}], ["thematicBreak", {
  pedantic: false
}], ["definition", {
  commonmark: false
}]]; // Nodes that can interupt a blockquote:
//
// ```markdown
// > A paragraph.
// ___
// ```
//
// In the above example, the thematic break “interupts” the blockquote.

proto.interruptBlockquote = [["indentedCode", {
  commonmark: true
}], ["fencedCode", {
  commonmark: true
}], ["atxHeading", {
  commonmark: true
}], ["setextHeading", {
  commonmark: true
}], ["thematicBreak", {
  commonmark: true
}], ["html", {
  commonmark: true
}], ["list", {
  commonmark: true
}], ["definition", {
  commonmark: false
}]]; // Handlers.

proto.blockTokenizers = {
  blankLine: _blankLine,
  indentedCode: _codeIndented,
  fencedCode: _codeFenced,
  blockquote: _blockquote,
  atxHeading: _headingAtx,
  thematicBreak: _thematicBreak,
  list: _list,
  setextHeading: _headingSetext,
  html: _htmlBlock,
  definition: _definition,
  table: _table,
  paragraph: _paragraph
};
proto.inlineTokenizers = {
  escape: _escape,
  autoLink: _autoLink,
  url: _url,
  email: _email,
  html: _htmlInline,
  link: _link,
  reference: _reference,
  strong: _strong,
  emphasis: _emphasis,
  deletion: _delete,
  code: _codeInline,
  break: _break,
  text: _text
}; // Expose precedence.

proto.blockMethods = keys(proto.blockTokenizers);
proto.inlineMethods = keys(proto.inlineTokenizers); // Tokenizers.

proto.tokenizeBlock = tokenizer("block");
proto.tokenizeInline = tokenizer("inline");
proto.tokenizeFactory = tokenizer; // Get all keys in `value`.

function keys(value) {
  var result = [];
  var key;

  for (key in value) {
    result.push(key);
  }

  return result;
}

export default exports;