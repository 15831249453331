import _repeatString from "repeat-string";
import _trimTrailingLines from "trim-trailing-lines";
var exports = {};
var repeat = _repeatString;
var trim = _trimTrailingLines;
exports = indentedCode;
var lineFeed = "\n";
var tab = "\t";
var space = " ";
var tabSize = 4;
var codeIndent = repeat(space, tabSize);

function indentedCode(eat, value, silent) {
  var index = -1;
  var length = value.length;
  var subvalue = "";
  var content = "";
  var subvalueQueue = "";
  var contentQueue = "";
  var character;
  var blankQueue;
  var indent;

  while (++index < length) {
    character = value.charAt(index);

    if (indent) {
      indent = false;
      subvalue += subvalueQueue;
      content += contentQueue;
      subvalueQueue = "";
      contentQueue = "";

      if (character === lineFeed) {
        subvalueQueue = character;
        contentQueue = character;
      } else {
        subvalue += character;
        content += character;

        while (++index < length) {
          character = value.charAt(index);

          if (!character || character === lineFeed) {
            contentQueue = character;
            subvalueQueue = character;
            break;
          }

          subvalue += character;
          content += character;
        }
      }
    } else if (character === space && value.charAt(index + 1) === character && value.charAt(index + 2) === character && value.charAt(index + 3) === character) {
      subvalueQueue += codeIndent;
      index += 3;
      indent = true;
    } else if (character === tab) {
      subvalueQueue += character;
      indent = true;
    } else {
      blankQueue = "";

      while (character === tab || character === space) {
        blankQueue += character;
        character = value.charAt(++index);
      }

      if (character !== lineFeed) {
        break;
      }

      subvalueQueue += blankQueue + character;
      contentQueue += character;
    }
  }

  if (content) {
    if (silent) {
      return true;
    }

    return eat(subvalue)({
      type: "code",
      lang: null,
      meta: null,
      value: trim(content)
    });
  }
}

export default exports;