import _parseEntities from "parse-entities";
import _isDecimal from "is-decimal";
import _isAlphabetical from "is-alphabetical";
import _email from "../locate/email";
var exports = {};
var decode = _parseEntities;
var decimal = _isDecimal;
var alphabetical = _isAlphabetical;
var locate = _email;
exports = email;
email.locator = locate;
email.notInLink = true;
var plusSign = 43; // '+'

var dash = 45; // '-'

var dot = 46; // '.'

var atSign = 64; // '@'

var underscore = 95; // '_'

function email(eat, value, silent) {
  var self = this;
  var gfm = self.options.gfm;
  var tokenizers = self.inlineTokenizers;
  var index = 0;
  var length = value.length;
  var firstDot = -1;
  var code;
  var content;
  var children;
  var exit;

  if (!gfm) {
    return;
  }

  code = value.charCodeAt(index);

  while (decimal(code) || alphabetical(code) || code === plusSign || code === dash || code === dot || code === underscore) {
    code = value.charCodeAt(++index);
  }

  if (index === 0) {
    return;
  }

  if (code !== atSign) {
    return;
  }

  index++;

  while (index < length) {
    code = value.charCodeAt(index);

    if (decimal(code) || alphabetical(code) || code === dash || code === dot || code === underscore) {
      index++;

      if (firstDot === -1 && code === dot) {
        firstDot = index;
      }

      continue;
    }

    break;
  }

  if (firstDot === -1 || firstDot === index || code === dash || code === underscore) {
    return;
  }

  if (code === dot) {
    index--;
  }

  content = value.slice(0, index);
  /* istanbul ignore if - never used (yet) */

  if (silent) {
    return true;
  }

  exit = self.enterLink(); // Temporarily remove all tokenizers except text in url.

  self.inlineTokenizers = {
    text: tokenizers.text
  };
  children = self.tokenizeInline(content, eat.now());
  self.inlineTokenizers = tokenizers;
  exit();
  return eat(content)({
    type: "link",
    title: null,
    url: "mailto:" + decode(content, {
      nonTerminated: false
    }),
    children: children
  });
}

export default exports;