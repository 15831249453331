var exports = {};
exports = thematicBreak;
var tab = "\t";
var lineFeed = "\n";
var space = " ";
var asterisk = "*";
var dash = "-";
var underscore = "_";
var maxCount = 3;

function thematicBreak(eat, value, silent) {
  var index = -1;
  var length = value.length + 1;
  var subvalue = "";
  var character;
  var marker;
  var markerCount;
  var queue;

  while (++index < length) {
    character = value.charAt(index);

    if (character !== tab && character !== space) {
      break;
    }

    subvalue += character;
  }

  if (character !== asterisk && character !== dash && character !== underscore) {
    return;
  }

  marker = character;
  subvalue += character;
  markerCount = 1;
  queue = "";

  while (++index < length) {
    character = value.charAt(index);

    if (character === marker) {
      markerCount++;
      subvalue += queue + marker;
      queue = "";
    } else if (character === space) {
      queue += character;
    } else if (markerCount >= maxCount && (!character || character === lineFeed)) {
      subvalue += queue;

      if (silent) {
        return true;
      }

      return eat(subvalue)({
        type: "thematicBreak"
      });
    } else {
      return;
    }
  }
}

export default exports;