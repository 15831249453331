var exports = {};
exports = locate;

function locate(value, fromIndex) {
  var asterisk = value.indexOf("**", fromIndex);
  var underscore = value.indexOf("__", fromIndex);

  if (underscore === -1) {
    return asterisk;
  }

  if (asterisk === -1) {
    return underscore;
  }

  return underscore < asterisk ? underscore : asterisk;
}

export default exports;