var exports = {};
exports = atxHeading;
var lineFeed = "\n";
var tab = "\t";
var space = " ";
var numberSign = "#";
var maxFenceCount = 6;

function atxHeading(eat, value, silent) {
  var self = this;
  var pedantic = self.options.pedantic;
  var length = value.length + 1;
  var index = -1;
  var now = eat.now();
  var subvalue = "";
  var content = "";
  var character;
  var queue;
  var depth; // Eat initial spacing.

  while (++index < length) {
    character = value.charAt(index);

    if (character !== space && character !== tab) {
      index--;
      break;
    }

    subvalue += character;
  } // Eat hashes.


  depth = 0;

  while (++index <= length) {
    character = value.charAt(index);

    if (character !== numberSign) {
      index--;
      break;
    }

    subvalue += character;
    depth++;
  }

  if (depth > maxFenceCount) {
    return;
  }

  if (!depth || !pedantic && value.charAt(index + 1) === numberSign) {
    return;
  }

  length = value.length + 1; // Eat intermediate white-space.

  queue = "";

  while (++index < length) {
    character = value.charAt(index);

    if (character !== space && character !== tab) {
      index--;
      break;
    }

    queue += character;
  } // Exit when not in pedantic mode without spacing.


  if (!pedantic && queue.length === 0 && character && character !== lineFeed) {
    return;
  }

  if (silent) {
    return true;
  } // Eat content.


  subvalue += queue;
  queue = "";
  content = "";

  while (++index < length) {
    character = value.charAt(index);

    if (!character || character === lineFeed) {
      break;
    }

    if (character !== space && character !== tab && character !== numberSign) {
      content += queue + character;
      queue = "";
      continue;
    }

    while (character === space || character === tab) {
      queue += character;
      character = value.charAt(++index);
    } // `#` without a queue is part of the content.


    if (!pedantic && content && !queue && character === numberSign) {
      content += character;
      continue;
    }

    while (character === numberSign) {
      queue += character;
      character = value.charAt(++index);
    }

    while (character === space || character === tab) {
      queue += character;
      character = value.charAt(++index);
    }

    index--;
  }

  now.column += subvalue.length;
  now.offset += subvalue.length;
  subvalue += content + queue;
  return eat(subvalue)({
    type: "heading",
    depth: depth,
    children: self.tokenizeInline(content, now)
  });
}

export default exports;