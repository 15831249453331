import _escape from "../locate/escape";
var exports = {};
var locate = _escape;
exports = escape;
escape.locator = locate;
var lineFeed = "\n";
var backslash = "\\";

function escape(eat, value, silent) {
  var self = this;
  var character;
  var node;

  if (value.charAt(0) === backslash) {
    character = value.charAt(1);

    if (self.escape.indexOf(character) !== -1) {
      /* istanbul ignore if - never used (yet) */
      if (silent) {
        return true;
      }

      if (character === lineFeed) {
        node = {
          type: "break"
        };
      } else {
        node = {
          type: "text",
          value: character
        };
      }

      return eat(backslash + character)(node);
    }
  }
}

export default exports;