import _xtend from "xtend";
import _markdownEscapes from "markdown-escapes";
import _defaults from "./defaults";
var exports = {};
var xtend = _xtend;
var escapes = _markdownEscapes;
var defaults = _defaults;
exports = setOptions;

function setOptions(options) {
  var self = this;
  var current = self.options;
  var key;
  var value;

  if (options == null) {
    options = {};
  } else if (typeof options === "object") {
    options = xtend(options);
  } else {
    throw new Error("Invalid value `" + options + "` for setting `options`");
  }

  for (key in defaults) {
    value = options[key];

    if (value == null) {
      value = current[key];
    }

    if (key !== "blocks" && typeof value !== "boolean" || key === "blocks" && typeof value !== "object") {
      throw new Error("Invalid value `" + value + "` for setting `options." + key + "`");
    }

    options[key] = value;
  }

  self.options = options;
  self.escape = escapes(options);
  return self;
}

export default exports;