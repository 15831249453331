import _trim from "trim";
import _isWhitespaceCharacter from "is-whitespace-character";
import _strong from "../locate/strong";
var exports = {};
var trim = _trim;
var whitespace = _isWhitespaceCharacter;
var locate = _strong;
exports = strong;
strong.locator = locate;
var backslash = "\\";
var asterisk = "*";
var underscore = "_";

function strong(eat, value, silent) {
  var self = this;
  var index = 0;
  var character = value.charAt(index);
  var now;
  var pedantic;
  var marker;
  var queue;
  var subvalue;
  var length;
  var previous;

  if (character !== asterisk && character !== underscore || value.charAt(++index) !== character) {
    return;
  }

  pedantic = self.options.pedantic;
  marker = character;
  subvalue = marker + marker;
  length = value.length;
  index++;
  queue = "";
  character = "";

  if (pedantic && whitespace(value.charAt(index))) {
    return;
  }

  while (index < length) {
    previous = character;
    character = value.charAt(index);

    if (character === marker && value.charAt(index + 1) === marker && (!pedantic || !whitespace(previous))) {
      character = value.charAt(index + 2);

      if (character !== marker) {
        if (!trim(queue)) {
          return;
        }
        /* istanbul ignore if - never used (yet) */


        if (silent) {
          return true;
        }

        now = eat.now();
        now.column += 2;
        now.offset += 2;
        return eat(subvalue + queue + subvalue)({
          type: "strong",
          children: self.tokenizeInline(queue, now)
        });
      }
    }

    if (!pedantic && character === backslash) {
      queue += character;
      character = value.charAt(++index);
    }

    queue += character;
    index++;
  }
}

export default exports;