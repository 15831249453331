import _trim from "trim";
import _isWordCharacter from "is-word-character";
import _isWhitespaceCharacter from "is-whitespace-character";
import _emphasis from "../locate/emphasis";
var exports = {};
var trim = _trim;
var word = _isWordCharacter;
var whitespace = _isWhitespaceCharacter;
var locate = _emphasis;
exports = emphasis;
emphasis.locator = locate;
var asterisk = "*";
var underscore = "_";
var backslash = "\\";

function emphasis(eat, value, silent) {
  var self = this;
  var index = 0;
  var character = value.charAt(index);
  var now;
  var pedantic;
  var marker;
  var queue;
  var subvalue;
  var length;
  var previous;

  if (character !== asterisk && character !== underscore) {
    return;
  }

  pedantic = self.options.pedantic;
  subvalue = character;
  marker = character;
  length = value.length;
  index++;
  queue = "";
  character = "";

  if (pedantic && whitespace(value.charAt(index))) {
    return;
  }

  while (index < length) {
    previous = character;
    character = value.charAt(index);

    if (character === marker && (!pedantic || !whitespace(previous))) {
      character = value.charAt(++index);

      if (character !== marker) {
        if (!trim(queue) || previous === marker) {
          return;
        }

        if (!pedantic && marker === underscore && word(character)) {
          queue += marker;
          continue;
        }
        /* istanbul ignore if - never used (yet) */


        if (silent) {
          return true;
        }

        now = eat.now();
        now.column++;
        now.offset++;
        return eat(subvalue + queue + marker)({
          type: "emphasis",
          children: self.tokenizeInline(queue, now)
        });
      }

      queue += marker;
    }

    if (!pedantic && character === backslash) {
      queue += character;
      character = value.charAt(++index);
    }

    queue += character;
    index++;
  }
}

export default exports;